<template>
    <el-date-picker
        v-model="value"
        :format="item.format || 'yyyy-MM-dd HH:mm:ss'"
        :value-format="item.format || 'yyyy-MM-dd HH:mm:ss'"
        :editable="false"
        :type="item.dateType || 'datetime'"
        :unlink-panels="true"
        :picker-options="
            item.dateType && item.dateType.includes('range')
                ? pickerOptions
                : {}
        "
    ></el-date-picker>
</template>

<script>
import { RESET_SEARCH } from "../../views/index/events/eventBus";
export default {
    data() {
        return {
            value: null,
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            let e = new Date();
                            const end = e,
                                start = new Date(
                                    e.getFullYear() +
                                        "/" +
                                        (parseInt(e.getMonth()) + 1) +
                                        "/" +
                                        e.getDate()
                                );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                            let end = new Date(),
                                start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            end.setTime(end.getTime() - 3600 * 1000 * 24);
                            start = new Date(
                                start.getFullYear() +
                                    "/" +
                                    (parseInt(start.getMonth()) + 1) +
                                    "/" +
                                    start.getDate()
                            );
                            end = new Date(
                                end.getFullYear() +
                                    "/" +
                                    (parseInt(end.getMonth()) + 1) +
                                    "/" +
                                    end.getDate() +
                                    " 23:59:59"
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "本周",
                        onClick(picker) {
                            let end = new Date(),
                                start = new Date();
                            start.setTime(
                                start.getTime() -
                                    3600 * 1000 * 24 * (start.getDay() - 1)
                            );
                            start = new Date(
                                start.getFullYear() +
                                    "/" +
                                    (parseInt(start.getMonth()) + 1) +
                                    "/" +
                                    start.getDate()
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "上周",
                        onClick(picker) {
                            let end = new Date(),
                                start = new Date();
                            start.setTime(
                                start.getTime() -
                                    3600 * 1000 * 24 * (start.getDay() + 6)
                            );
                            start = new Date(
                                start.getFullYear() +
                                    "/" +
                                    (parseInt(start.getMonth()) + 1) +
                                    "/" +
                                    start.getDate()
                            );
                            end.setTime(
                                end.getTime() - 3600 * 1000 * 24 * end.getDay()
                            );
                            end = new Date(
                                end.getFullYear() +
                                    "/" +
                                    (parseInt(end.getMonth()) + 1) +
                                    "/" +
                                    end.getDate() +
                                    " 23:59:59"
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "本月",
                        onClick(picker) {
                            let end = new Date(),
                                start = new Date();
                            start.setTime(
                                start.getTime() -
                                    3600 * 1000 * 24 * (start.getDate() - 1)
                            );
                            start = new Date(
                                start.getFullYear() +
                                    "/" +
                                    (parseInt(start.getMonth()) + 1) +
                                    "/" +
                                    start.getDate()
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "上月",
                        onClick(picker) {
                            let now = new Date(),
                                lastMonth = now.getMonth() - 1,
                                lastmYear = now.getFullYear();
                            if (lastMonth < 0) {
                                lastMonth = 12;
                                lastmYear = lastmYear - 1;
                            }
                            let mouthStart = new Date(lastmYear, lastMonth, 1),
                                mouthEnd = new Date(
                                    lastmYear,
                                    lastMonth + 1,
                                    0
                                );
                            mouthEnd = new Date(
                                mouthEnd.getFullYear() +
                                    "/" +
                                    (parseInt(mouthEnd.getMonth()) + 1) +
                                    "/" +
                                    mouthEnd.getDate() +
                                    " 23:59:59"
                            );
                            picker.$emit("pick", [mouthStart, mouthEnd]);
                        }
                    }
                ]
            }
        };
    },
    props: {
        data: { type: Object, default: null },
        item: { type: Object, default: null }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.$set(this.data, this.item.key, this.value);
                if (this.item.range) {
                    if (this.value) {
                        this.$set(this.data, this.item.keys[0], this.value[0]);
                        this.$set(this.data, this.item.keys[1], this.value[1]);
                    } else {
                        this.$delete(this.data, this.item.keys[0]);
                        this.$delete(this.data, this.item.keys[1]);
                    }
                }
            }
        }
    },
    mounted() {
        this.$EventBus.$on(RESET_SEARCH, this.reset);
        if (this.item.range) {
            this.value = [
                this.data[this.item.keys[0]] || "",
                this.data[this.item.keys[1]] || ""
            ];
        } else {
            this.value = this.data[this.item.key];
        }
    },
    methods: {
        reset() {
            this.value = null;
        }
    }
};
</script>

<style lang="scss">
.el-date-editor.el-input,
.el-date-editor--datetimerange.el-input__inner {
    width: 100%;
    // min-width: 400px;
}
</style>